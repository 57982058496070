import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import theme from "theme";

import { ROUTE_COMMERCIAL_LOSSES_PAGE, ROUTE_MINING_PAGE } from "stream-constants/route-constants";
import { SectionTitle } from "components/section-title/section-title";
import { ProductCard } from "components/product-card/product-card";
import { GridInfo } from "components/grid-info/grid-info";
import { GridInfoCard } from "components/grid-info-card/grid-info-card";
import { AccordionCustom } from "components/accordion-custom/accordion-custom";
import IntroSection from "components/intro-section";
import { MTSList } from "components/mts-list/mts-list";
import { PromoSection } from "components/section-promo/section-promo";
import { InfoSection } from "components/section-info/section-info";
import { Spacer } from "components/spacer/spacer.component";
import { CustomTypography } from "components/customTypography/custom-typography";
import { accordionInfo, advantageData, miningList, productsCard, seo } from "./const";
import { domain } from "pages/shopCart/const";
import {
  InfoBody,
  SAdvantage,
  SContainer,
  SGrid,
  SMain,
  SPromoSubText,
  SPromoText,
  SPromoTitle,
  SSection,
} from "./styles";

import bgImg from "./images/mining-landing-bg.png";
import promoSectionBgImg from "./images/promo-mining-bg.png";
import infoSectionImg from "./images/img-phone-big-data.png";

export const LandingMining = () => {
  return (
    <>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <link rel="canonical" href={`${domain}/${ROUTE_MINING_PAGE}`} />
      </Helmet>
      <SMain>
        <IntroSection
          title={"Выявление\nмайнинговых ферм"}
          text="Комплексная аналитика локаций"
          subtext="С начала 2022 года в России насчитывается около 300&nbsp;тыс. физических и&nbsp;юридических лиц, которые занимаются добычей крипто валют"
          withConsultBtn={true}
          bgImg={bgImg}
          isFullScreen
        />
        <SAdvantage>
          <SContainer>
            <SectionTitle title="Как это работает?" />
            <div>
              <GridInfo isShort>
                {advantageData.map((item, index) => {
                  return (
                    <GridInfoCard
                      key={item.title}
                      icon={item.icon}
                      title={item.title}
                      description={item.description}
                    />
                  );
                })}
              </GridInfo>
            </div>
          </SContainer>
        </SAdvantage>
        <PromoSection bgImg={promoSectionBgImg} maxWidth="60%">
          <>
            <SPromoTitle>
              <CustomTypography variant="promo1_bold" font="wide">
                11,2 %
              </CustomTypography>
            </SPromoTitle>
            <SPromoText>
              <CustomTypography variant="h3_medium" font="wide">
                {`составляет доля российских вычислительных мощностей в\u00A0общемировом объеме майнинга`}
              </CustomTypography>
            </SPromoText>
            <SPromoSubText>
              <CustomTypography variant="p3_regular" font="comp">
                {`Незаконный майнинг влияет на качество и\u00A0надежность электроснабжения потребителей, так
              как бытовая сеть не\u00A0рассчитана на большие нагрузки, связанные с\u00A0сверхвысоким
              энергопотреблением!`}
              </CustomTypography>
            </SPromoSubText>
          </>
        </PromoSection>
        <InfoSection img={infoSectionImg}>
          <InfoBody>
            <SectionTitle title="Решение на основе BigData" />
            <MTSList
              items={miningList}
              styles={{ fontWeight: "400", color: theme.mtsColor.text.primary.lightMode }}
            />
            <p>
              <CustomTypography variant="p2_regular" font="comp">
                Майнинговые фермы имеют высокое потребление электроэнергии, что провоцирует взлом
                приборов учета для «экономии».
              </CustomTypography>
            </p>
            <Spacer value="32px" />
            <p>
              <CustomTypography variant="p2_regular" font="comp">
                Программный комплекс
                <Link to={`/${ROUTE_COMMERCIAL_LOSSES_PAGE}`}>
                  <CustomTypography variant="p2_regular" font="comp">
                    {" "}
                    "Поиск коммерческих потерь"{" "}
                  </CustomTypography>
                </Link>
                поможет своевременно обнаружить конкретный участок сети с хищением электроэнергии.
              </CustomTypography>
            </p>
          </InfoBody>
        </InfoSection>
        <SSection greyBg>
          <SContainer>
            <SectionTitle title="Подключите другие наши продукты" />
            <SGrid>
              {productsCard.map((item, index) => {
                return (
                  <ProductCard
                    key={index}
                    title={item.title}
                    description={item.description}
                    nameBtn={item.nameBtn}
                    isCallbackBlock={item.isCallbackBlock}
                    img={item.img}
                    link={item.link}
                  />
                );
              })}
            </SGrid>
          </SContainer>
        </SSection>
        <SSection>
          <SContainer>
            <AccordionCustom data={accordionInfo} />
          </SContainer>
        </SSection>
      </SMain>
    </>
  );
};
