import { Helmet } from "react-helmet";

import { CustomTypography } from "components/customTypography/custom-typography";
import { ROUTE_OFFER_INSTALLATION } from "stream-constants/route-constants";
import { Spacer } from "components/spacer/spacer.component";
import { useBreakpoint } from "hooks/useBreakpoint";

import { domain } from "pages/shopCart/const";
import { pageContent, pageTitle, seo } from "./const";
import { SArticle, SContainer, SMain, SParag } from "./styles";

export const OfferInstallation = () => {
  const { isTablet } = useBreakpoint();
  return (
    <>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <link rel="canonical" href={`${domain}/${ROUTE_OFFER_INSTALLATION}`} />
      </Helmet>
      <SMain>
        <SContainer>
          <SArticle>
            <h1>
              <CustomTypography variant={isTablet() ? "h3_medium" : "h1_medium"} font="wide">
                {pageTitle}
              </CustomTypography>
              <Spacer value="48px" />
            </h1>
            <div>
              <SParag>
                {pageContent.map((item, index) => (
                  <>
                    {item.title ? (
                      <CustomTypography key={`title_${index}`} variant="p3_bold">
                        {item.title}
                      </CustomTypography>
                    ) : null}
                    {item.text.map((text, i) => (
                      <CustomTypography variant="p3_regular" key={`text_${i}`}>
                        <span dangerouslySetInnerHTML={{ __html: text }} />
                      </CustomTypography>
                    ))}
                  </>
                ))}
              </SParag>
            </div>
          </SArticle>
        </SContainer>
      </SMain>
    </>
  );
};
