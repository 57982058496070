import { CustomTypography } from "components/customTypography/custom-typography";
import { Spacer } from "components/spacer/spacer.component";
import { MTSButton } from "components/mts-button/mts-button";
import { useBreakpoint } from "hooks/useBreakpoint";
import { SBtn, SText, STitle } from "./styles";

interface IProps {
  handleClickBtn: () => void;
}

export const AskuePromo = ({ handleClickBtn }: IProps) => {
  const { isTablet } = useBreakpoint();
  return (
    <>
      <STitle>
        <CustomTypography variant={isTablet() ? "h3_bold" : "h2_bold"} font="wide">
          Автоматизированная система учета
        </CustomTypography>
        <CustomTypography variant={isTablet() ? "h4_regular" : "h3_regular"} font="wide">
          со скидкой 100% на 12 месяцев
        </CustomTypography>
      </STitle>
      <Spacer value="24px" />
      <SText>
        <CustomTypography variant="p3_regular" font="comp">
          При покупке приборов учета{" "}
          <CustomTypography variant="p3_medium" font="comp">
            Меркурий 204 ARTM 2-02(D)POBH.G5
          </CustomTypography>{" "}
          и{" "}
          <CustomTypography variant="p3_medium" font="comp">
            Меркурий 234 ARTM(X)2-02 (D)PОBR.G5
          </CustomTypography>
          , открываем возможность протестировать услугу «Энергосфера в облаке МТС по операторской
          модели», которая помогает просто, без особых сложностей создать комфортную систему
          управления приборами и учёта данных
        </CustomTypography>
      </SText>
      <Spacer value="24px" />
      <SBtn>
        <MTSButton variant="primary" size="L" onClick={handleClickBtn}>
          Подключить
        </MTSButton>
      </SBtn>
    </>
  );
};
