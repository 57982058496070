export const calcSeo = (id, name: string, price: number, category: string, promo?: string) => {
  switch (id) {
    case "25":
    case "6":
    case "8":
      return {
        title: `Счетчик электроэнергии ${name} с аскуэ на 12 месяцев купить за ${renderPriceProd(
          price,
        )}`,
        desc: `Счетчик электроэнергии ${name} с аскуэ на 12 месяцев по хорошей цене ${renderPriceProd(
          price,
        )} на EnergyTool. Приобретайте оборудование для оптимизации энергетических расходов вашей организации`,
      };
    case "9":
    case "10":
    case "11":
    case "12":
    case "16":
      return {
        title: `Купить ${name} по хорошей цене на EnergyTool`,
        desc: "Приобретайте оборудование для оптимизации энергетических расходов вашей организации",
      };
    default:
      return {
        title: `${renderNameProd(name, category)} купить за ${renderPriceProd(price)}`,
        desc: `${renderNameProd(name, category)} по хорошей цене ${renderPriceProd(
          price,
        )} руб. на EnergyTool. Приобретайте оборудование для оптимизации энергетических расходов вашей организации`,
      };
  }
};

const renderNameProd = (name: string, category: string) => `${category} ${name}`;
const renderPriceProd = (price: number) => (price ? `${price} рублей` : "по хорошей цене");
