import { useContext } from "react";
import _ from "lodash";

import { login } from "services/auth";
import { MTSButton } from "components/mts-button/mts-button";
import { ContextSettings } from "context/context-settings";
import { GridCard } from "components/grid-card/grid-card";
import { IntroCard } from "components/intro-card/intro-card";
import { Spacer } from "components/spacer/spacer.component";
import { CustomTypography } from "components/customTypography/custom-typography";

import {
  SArticle,
  SBtns,
  SCardBlock,
  SContainer,
  SGeo,
  SLanding,
  SSubtext,
  SText,
  STitle,
} from "./styles";

interface IntroSectionProps {
  title: string;
  text?: string;
  subtext?: string;
  withConsultBtn?: boolean;
  withLoginBtn?: boolean;
  bgImg?: string;
  infoCardsBlock?: any;
  isFullScreen?: boolean;
}

const IntroSection = (props: IntroSectionProps) => {
  const {
    title,
    text,
    subtext,
    withConsultBtn,
    withLoginBtn,
    bgImg,
    infoCardsBlock,
    isFullScreen,
  } = props;
  const context = useContext(ContextSettings);

  return (
    <SLanding isFullScreen={isFullScreen}>
      <SArticle>
        <SGeo style={{ backgroundImage: `url(${bgImg})` }}>
          <SContainer>
            <div>
              <STitle isFullScreen={isFullScreen}>
                <CustomTypography variant="promo3_bold" font="wide">
                  {title}
                </CustomTypography>
              </STitle>
              {text ? (
                <>
                  <Spacer value="16px" />
                  <SText>
                    <CustomTypography variant="h4_medium" font="wide">
                      {text}
                    </CustomTypography>
                  </SText>
                </>
              ) : null}
              {subtext && (
                <>
                  <Spacer value={text ? "32px" : "16px"} />
                  <SSubtext>
                    <CustomTypography variant="p3_regular" font="comp">
                      {subtext}
                    </CustomTypography>
                  </SSubtext>
                </>
              )}
              {infoCardsBlock && (
                <SCardBlock>
                  <div>
                    <GridCard>
                      {!_.isEmpty(infoCardsBlock)
                        ? infoCardsBlock.map((item: any, index: number) => {
                            return (
                              <IntroCard
                                key={index}
                                img={item.img}
                                title={item.title}
                                text={item.text}
                                numbered={index + 1}
                              />
                            );
                          })
                        : null}
                    </GridCard>
                  </div>
                </SCardBlock>
              )}
              {(withConsultBtn || withLoginBtn) && (
                <SBtns>
                  {withConsultBtn && (
                    <MTSButton size="L" onClick={() => context.changeStateFormConsultation(true)}>
                      Заказать консультацию
                    </MTSButton>
                  )}
                  {withLoginBtn && (
                    <MTSButton size="L" variant="outline_light" onClick={login}>
                      Войти в кабинет
                    </MTSButton>
                  )}
                </SBtns>
              )}
            </div>
          </SContainer>
        </SGeo>
      </SArticle>
    </SLanding>
  );
};

export default IntroSection;
