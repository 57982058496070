import styled from "@emotion/styled";
import { device } from "theme";

export const SWrap = styled.div`
  padding-top: 40px;
  width: 100%;
  background-color: ${(props) => props.theme.mtsColor.background.primary.lightMode};

  @media ${device.tabletPlus} {
    padding-top: 0;
    background-color: ${(props) => props.theme.mtsColor.background.primaryElevated.lightMode};
  }
`;

export const SContainer = styled.div`
  max-width: 1304px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
`;
