import { Helmet } from "react-helmet";

import { CustomTypography } from "components/customTypography/custom-typography";
import { SectionTitle } from "components/section-title/section-title";
import { ROUTE_CONDITION_PROMOTION } from "stream-constants/route-constants";

import { domain } from "pages/shopCart/const";
import {
  blockFive,
  blockFourPrtOne,
  blockFourPrtTwo,
  blockOne,
  blockSix,
  blockThree,
  blockTwo,
  seo,
} from "./const";
import { SArticle, SContainer, SMain, SParag, STable } from "./styles";

export const ConditionPromotion = () => {
  return (
    <>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <link rel="canonical" href={`${domain}/${ROUTE_CONDITION_PROMOTION}`} />
      </Helmet>
      <SMain>
        <SContainer>
          <SArticle>
            <SectionTitle title="Условия проведения акции «АСКУЭ в облаке со скидкой 100 % на 12 месяцев» (далее – Условия)" />
            <div>
              <SParag>
                <CustomTypography variant="p3_bold" font="comp">
                  {blockOne.title}
                </CustomTypography>
                {blockOne.text.map((item, i) => (
                  <p key={`blockOne_${i}`} dangerouslySetInnerHTML={{ __html: item }} />
                ))}

                <CustomTypography variant="p3_bold" font="comp">
                  {blockTwo.title}
                </CustomTypography>
                {blockTwo.text.map((item, i) => (
                  <p key={`blockTwo_${i}`} dangerouslySetInnerHTML={{ __html: item }} />
                ))}

                <CustomTypography variant="p3_bold" font="comp">
                  {blockThree.title}
                </CustomTypography>
                {blockThree.text.map((item, i) => (
                  <p key={`blockThree_${i}`} dangerouslySetInnerHTML={{ __html: item }} />
                ))}

                <CustomTypography variant="p3_bold" font="comp">
                  {blockFourPrtOne.title}
                </CustomTypography>
                {blockFourPrtOne.text.map((item, i) => (
                  <p key={`blockFourPrtOne_${i}`} dangerouslySetInnerHTML={{ __html: item }} />
                ))}

                <STable>
                  <table>
                    <tbody>
                      <tr>
                        <td>Наименование Оборудования</td>
                        <td>Стоимость оборудования без учёта НДС</td>
                      </tr>
                      <tr>
                        <td>Меркурий 204 ARTM2-02 (D)POBH.G5 (1-фазный с NB-IoT)</td>
                        <td>11484</td>
                      </tr>
                      <tr>
                        <td>
                          Меркурий 234 ARTMX2-02 (D)PОBR.G5 (3-фазный прямого включения с NB IoT)
                        </td>
                        <td>18700</td>
                      </tr>
                    </tbody>
                  </table>
                </STable>

                {blockFourPrtTwo.map((item, i) => (
                  <p key={`blockFourPrtTwo_${i}`} dangerouslySetInnerHTML={{ __html: item }} />
                ))}

                <CustomTypography variant="p3_bold" font="comp">
                  {blockFive.title}
                </CustomTypography>
                {blockFive.text.map((item, i) => (
                  <p key={`blockFive_${i}`} dangerouslySetInnerHTML={{ __html: item }} />
                ))}

                <CustomTypography variant="p3_bold" font="comp">
                  {blockSix.title}
                </CustomTypography>
                {blockSix.text.map((item, i) => (
                  <p key={`blockSix_${i}`} dangerouslySetInnerHTML={{ __html: item }} />
                ))}
              </SParag>
            </div>
          </SArticle>
        </SContainer>
      </SMain>
    </>
  );
};
