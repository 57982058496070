import { memo, useCallback, useEffect } from "react";
import {
  Column,
  useSortBy,
  useTable,
  useGlobalFilter,
  useFilters,
  useBlockLayout,
  useRowSelect,
} from "react-table";
import { isEmpty } from "lodash";
import { STable, STableTitle } from "./styles";

export interface ITableFilterClm {
  column: string;
  value: any;
}
interface IProp {
  data: {
    [key: string]: any;
  }[];
  columns: Column<any>[];
  title?: string;
  globalFilter?: string | any;
  filterByColumn?: ITableFilterClm[];
  onRowsChange?: Function;
}

export interface ITableSortColumn {
  id: string; //accessor
  desc: boolean;
}

export const Table: React.FC<IProp> = memo(
  ({ columns, data, globalFilter, onRowsChange, title, filterByColumn }) => {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      setGlobalFilter,
      setAllFilters,
      toggleAllRowsSelected,
    } = useTable(
      {
        columns,
        data,
      },
      useBlockLayout,
      useFilters,
      useGlobalFilter,
      useSortBy,
      useRowSelect,
    );

    const handleRowChange = useCallback((a) => {
      onRowsChange?.(a);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      setGlobalFilter(globalFilter);
    }, [globalFilter, setGlobalFilter]);

    useEffect(() => {
      if (filterByColumn) {
        toggleAllRowsSelected(false);
        const newArr = filterByColumn
          ?.reduce((sum, cur) => {
            if (!isEmpty(cur)) sum.push(cur);
            return sum;
          }, [] as any)
          .map((a) => ({ id: a.column, value: a.value }));
        setAllFilters(newArr);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(filterByColumn), setAllFilters]);

    useEffect(() => {
      handleRowChange?.(rows.map((a) => a.original));
    }, [rows, handleRowChange]);

    return (
      <>
        <STable {...getTableProps()}>
          {rows.length && title ? (
            <h2>
              <STableTitle variant="h3_medium" font="wide">
                {title}
              </STableTitle>
            </h2>
          ) : null}
          {rows.length ? (
            <div>
              {headerGroups.map((item) => (
                <div {...item.getHeaderGroupProps()} className="table_tr">
                  {item.headers.map((col) => (
                    <div
                      {...col.getHeaderProps({
                        ...col.getSortByToggleProps(),
                        style: {
                          width: col.width,
                          minWidth: col.minWidth,
                          maxWidth: col.maxWidth,
                        },
                      })}
                    >
                      {col.render("Header")}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ) : null}
          <div {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className="table_tr">
                  {row.cells.map((cell) => cell.render("Cell"))}
                </div>
              );
            })}
          </div>
        </STable>
      </>
    );
  },
);
