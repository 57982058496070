import { useContext } from "react";
import { Link } from "react-router-dom";

import { CustomTypography } from "components/customTypography/custom-typography";
import { Spacer } from "components/spacer/spacer.component";
import { MTSButton } from "components/mts-button/mts-button";
import { ROUTE_ASKUE_OPP } from "stream-constants/route-constants";
import { ContextSettings } from "context/context-settings";
import { IShopProduct } from "interface/common";
import { RubleSymbol } from "components/ruble-simbol/ruble.component";
import { STitle, SSubTitle, SParag, SList, SItem, SBanner, SContent, SBtns } from "./styles";

import bannerImg from "./images/banner_bg.png";

export const renderPar = (par: any) => (
  <div>
    <STitle>
      <CustomTypography variant="h2_medium" font="comp">
        {par.title}
      </CustomTypography>
    </STitle>

    {par.blocks.map((block, index) => (
      <div key={`block-${par.id}-${index}`}>
        {block.subtitle ? (
          <SSubTitle>
            <CustomTypography variant="h3_medium" font="comp">
              {block.subtitle}
            </CustomTypography>
          </SSubTitle>
        ) : null}

        {block.text.map((cont, i) => {
          if (typeof cont === "string") {
            return (
              <SParag key={`parag-${par.id}-${i}`}>
                <CustomTypography variant="p3_regular" font="comp">
                  <span dangerouslySetInnerHTML={{ __html: cont }}></span>
                </CustomTypography>
              </SParag>
            );
          } else {
            return (
              <SList key={`list-${par.id}-${i}`}>
                {cont.map((item, j) => (
                  <SItem key={`item-${i}${j}`}>
                    <CustomTypography key={item} variant="p3_regular" font="comp">
                      {item}
                    </CustomTypography>
                  </SItem>
                ))}
              </SList>
            );
          }
        })}
      </div>
    ))}
  </div>
);

export const filteredTitles = (textObj: any) =>
  Object.values(textObj)
    .map((item: any) => ({
      id: item?.id,
      title: item?.title.replace(/[^?]$/, ""),
    }))
    .filter((item) => item.title !== "");

export const BannerAskue60 = ({ id, name, type, price, desc }: IShopProduct) => {
  const { setCartAmount } = useContext(ContextSettings);
  return (
    <SBanner bannerImg={bannerImg}>
      <SContent>
        <CustomTypography variant="h2_medium" font="wide">
          {name}
        </CustomTypography>
        <CustomTypography variant="p1_regular" font="wide">
          {type}
        </CustomTypography>
        <Spacer value="16px" />
        <CustomTypography variant="h1_medium" font="wide">
          от {price} <RubleSymbol variant="h1_medium" font="wide" />
          /месяц
        </CustomTypography>
        <Spacer value="16px" />
        <CustomTypography variant="c1_regular" font="comp">
          {desc}
        </CustomTypography>
      </SContent>
      <Spacer value="32px" />
      <SBtns>
        <MTSButton size="S" onClick={() => setCartAmount({ id, quant: 1 })}>
          Подключить
        </MTSButton>
        <Link to={`/${ROUTE_ASKUE_OPP}`} target="_blank">
          <MTSButton size="S" variant="outline_light" onClick={() => {}}>
            Подробнее
          </MTSButton>
        </Link>
      </SBtns>
    </SBanner>
  );
};
