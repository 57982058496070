import { TypographyTypeMap } from "@mui/material";
import { SRuble } from "./styles";

interface IProp {
  variant?: TypographyTypeMap["props"]["variant"];
  styles?: React.CSSProperties;
  font?: "sans" | "wide" | "comp";
}

export const RubleSymbol: React.FC<IProp> = ({ variant, styles, font }) => {
  return (
    <SRuble styles={styles} variant={variant} font={font}>
      <span>руб.</span>
    </SRuble>
  );
};
