import { Helmet } from "react-helmet";

import { SectionTitle } from "components/section-title/section-title";
import { ProductCard } from "components/product-card/product-card";
import { AccordionCustom } from "components/accordion-custom/accordion-custom";
import IntroSection from "components/intro-section";
import { MTSList } from "components/mts-list/mts-list";
import { InfoSection } from "components/section-info/section-info";
import { Calculator } from "components/calculator/calculator";
import { CustomTypography } from "components/customTypography/custom-typography";
import { ROUTE_COMMERCIAL_LOSSES_PAGE } from "stream-constants/route-constants";

import { SMain, SSectionGrey, SContainer, SGrid, SSection, SSubtitle, SFootnote } from "./styles";
import { ListInfo, accordionInfo, introInfoBlocks, productsCard, seo } from "./const";
import { domain } from "pages/shopCart/const";

import bgImg from "./images/mining-landing-bg.png";
import infoSectionImg from "./images/img-pilot.png";

export const LandingCommercialLosses = () => {
  return (
    <>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <link rel="canonical" href={`${domain}/${ROUTE_COMMERCIAL_LOSSES_PAGE}`} />
      </Helmet>
      <SMain>
        <IntroSection
          title={"Поиск\nкоммерческих потерь"}
          text="В электрических сетях с аналитикой"
          bgImg={bgImg}
          infoCardsBlock={introInfoBlocks}
          isFullScreen
        />
        <SSectionGrey isMobTransparent={true}>
          <SContainer>
            <Calculator />
          </SContainer>
        </SSectionGrey>
        <InfoSection img={infoSectionImg} withBtnDemo>
          <>
            <SectionTitle
              title="Попробуйте возможности платформы в пилотном проекте"
              styles={{ marginBottom: "16px" }}
            />
            <SSubtitle>
              <CustomTypography variant="p2_regular" font="comp">
                Программная платформа позволит быстро и своевременно обнаружить точки коммерческих
                потерь и выявить конкретные участки сети, на которых происходит хищение
              </CustomTypography>
            </SSubtitle>
            <MTSList items={ListInfo} styles={{ fontWeight: "600" }} />
            <SFootnote>
              <CustomTypography variant="p4_regular">
                *Экономия может отличаться от условий эксплуатации
              </CustomTypography>
            </SFootnote>
          </>
        </InfoSection>
        <SSectionGrey>
          <SContainer>
            <SectionTitle title="Подключите другие наши продукты" />
            <SGrid>
              {productsCard.map((item, index) => {
                return (
                  <ProductCard
                    key={item.title}
                    title={item.title}
                    description={item.description}
                    nameBtn={item.nameBtn}
                    isCallbackBlock={item.isCallbackBlock}
                    img={item.img}
                    link={item.link}
                  />
                );
              })}
            </SGrid>
          </SContainer>
        </SSectionGrey>
        <SSection>
          <SContainer>
            <AccordionCustom data={accordionInfo} />
          </SContainer>
        </SSection>
      </SMain>
    </>
  );
};
