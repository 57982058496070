import { TypographyTypeMap } from "@mui/material";
import { RubleSymbol } from "components/ruble-simbol/ruble.component";

export const formatPrice = (val: number | string) =>
  val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " ₽";

interface IProp {
  val: string | number;
  variant?: TypographyTypeMap["props"]["variant"];
  font?: "sans" | "wide" | "comp";
}

export const FormatPrice: React.FC<IProp> = ({ val, variant, font }) => (
  <span>
    {val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
    <RubleSymbol variant={variant} font={font} />
  </span>
);
