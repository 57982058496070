import { Helmet } from "react-helmet";
import _ from "lodash";

import { CustomTypography } from "components/customTypography/custom-typography";
import { ROUTE_USER_AGREEMENT } from "stream-constants/route-constants";
import { useBreakpoint } from "hooks/useBreakpoint";
import { Spacer } from "components/spacer/spacer.component";
import { domain } from "pages/shopCart/const";
import { seo, text } from "./const";
import { SArticle, SContainer, SMain, SList, SItem, SParag } from "./styles";

export const UserAgreement = () => {
  const { isTablet } = useBreakpoint();
  return (
    <>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <link rel="canonical" href={`${domain}/${ROUTE_USER_AGREEMENT}`} />
      </Helmet>
      <SMain>
        <SContainer>
          <SArticle>
            <h1>
              <CustomTypography variant={isTablet() ? "h3_medium" : "h1_medium"} font="wide">
                Согласие на обработку персональных данных
              </CustomTypography>
              <Spacer value="48px" />
            </h1>
            <div>
              <SParag>
                <CustomTypography variant="p3_regular" font="comp">
                  {text.par_1}
                </CustomTypography>
              </SParag>
              <SList>
                {text.list.map((elem) => {
                  return (
                    <SItem>
                      {_.isArray(elem) ? (
                        elem.map((el, i) => (
                          <>
                            <CustomTypography variant="p3_regular" font="comp">
                              {el}
                            </CustomTypography>
                            <br />
                          </>
                        ))
                      ) : (
                        <CustomTypography variant="p3_regular" font="comp">
                          {elem}
                        </CustomTypography>
                      )}
                    </SItem>
                  );
                })}
              </SList>
              <SParag>
                <CustomTypography variant="p3_regular" font="comp">
                  {text.par_2}
                </CustomTypography>
              </SParag>
              <SParag>
                <CustomTypography variant="p3_regular" font="comp">
                  {text.par_3}
                </CustomTypography>
              </SParag>
            </div>
          </SArticle>
        </SContainer>
      </SMain>
    </>
  );
};
