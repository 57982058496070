import { createTheme } from "@mui/material/styles";
import { TypographyStyles } from "./types";

//
// По умолчанию font-size равен 14px в HTML (см. index.css) = 1rem
//
const promo1_styles: TypographyStyles = {
  lineHeight: "1",
  fontSize: "5.14rem", // 72px
  color: "#000000",
};

const promo2_styles: TypographyStyles = {
  lineHeight: "1",
  fontSize: "4rem", // 56px
  color: "#000000",
};

const promo3_styles: TypographyStyles = {
  lineHeight: "1",
  fontSize: "3.14rem", // 44px
  color: "#000000",
};

const h1_styles: TypographyStyles = {
  lineHeight: "1.111", // 40px
  fontSize: "2.57rem", // 36px
  color: "#000000",
};

const h2_styles: TypographyStyles = {
  lineHeight: "1.125", // 36px
  fontSize: "2.29rem", // 32px
  color: "#000000",
};

const h3_styles: TypographyStyles = {
  lineHeight: "1.166", // 28px
  fontSize: "1.714rem", // 24px
  color: "#000000",
};

const h4_styles: TypographyStyles = {
  lineHeight: "1.2", // 24px
  fontSize: "1.43rem", // 20px
  color: "#000000",
};

export const p1_styles: TypographyStyles = {
  lineHeight: "1.333", // 32px
  fontSize: "1.714rem", // 24px
  color: "#1D2023",
};

const p2_styles: TypographyStyles = {
  lineHeight: "1.4", // 28px
  fontSize: "1.43rem", // 20px
  color: "#1D2023",
};

const p3_styles: TypographyStyles = {
  lineHeight: "1.4", // 24px
  fontSize: "1.214rem", // 17px
  color: "#1D2023",
};

const p4_styles: TypographyStyles = {
  lineHeight: "1.43", // 20px
  fontSize: "1rem", // 14px
  color: "#1D2023",
};

const c1_styles: TypographyStyles = {
  lineHeight: "1.33", // 16px
  fontSize: "0.86rem", // 12px
  color: "#1D2023",
};

const c2_styles: TypographyStyles = {
  lineHeight: "1.2", // 12px
  fontSize: "0.714rem", // 10px
  color: "#1D2023",
};

//
// Корпоративные шрифты: https://design.mts.ru/ds/components/base/base-typography (см. index.css)
//
export const MTS_TEXT = "MTSText";
export const MTS_WIDE = "MTSWide";
export const MTS_COMPACT = "MTSCompact";

export const OFFSET_24 = "24px";

export const breakpoints = {
  mobile: 430,
  miniTablet: 768,
  tablet: 1024,
  desktop: 1200,
};

export const device = {
  mobilePlus: `(min-width: ${breakpoints.mobile}px)`,
  mobileNeg: `(max-width: ${breakpoints.mobile - 1}px)`,
  miniTabletPlus: `(min-width: ${breakpoints.miniTablet}px)`,
  miniTabletNeg: `(max-width: ${breakpoints.miniTablet - 1}px)`,
  tabletPlus: `(min-width: ${breakpoints.tablet}px)`,
  tabletNeg: `(max-width: ${breakpoints.tablet - 1}px)`,
  desktopPlus: `(min-width: ${breakpoints.desktop}px)`,
  desktopNeg: `(max-width: ${breakpoints.desktop - 1}px)`,
};

const theme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: "#1D2023",
        },
        tooltip: {
          ...p2_styles,
          color: "#FFFFFF",
          backgroundColor: "#1D2023",
          padding: "10px 12px",
          borderRadius: "8px",
        },
      },
    },
  },
  typography: {
    fontFamily: [MTS_TEXT, "Segoe UI", "sans-serif"].join(","),

    promo1_black: {
      fontWeight: 900,
      ...promo1_styles,
    },
    promo1_bold: {
      fontWeight: 700,
      ...promo1_styles,
    },
    promo1_medium: {
      fontWeight: 500,
      ...promo1_styles,
    },
    promo1_regular: {
      fontWeight: 400,
      ...promo1_styles,
    },

    promo2_black: {
      fontWeight: 900,
      ...promo2_styles,
    },
    promo2_bold: {
      fontWeight: 700,
      ...promo2_styles,
    },
    promo2_medium: {
      fontWeight: 500,
      ...promo2_styles,
    },
    promo2_regular: {
      fontWeight: 400,
      ...promo2_styles,
    },

    promo3_black: {
      fontWeight: 900,
      ...promo3_styles,
    },
    promo3_bold: {
      fontWeight: 700,
      ...promo3_styles,
    },
    promo3_medium: {
      fontWeight: 500,
      ...promo3_styles,
    },
    promo3_regular: {
      fontWeight: 400,
      ...promo3_styles,
    },

    h1_black: {
      fontWeight: 900,
      ...h1_styles,
    },
    h1_bold: {
      fontWeight: 700,
      ...h1_styles,
    },
    h1_medium: {
      fontWeight: 500,
      ...h1_styles,
    },
    h1_regular: {
      fontWeight: 400,
      ...h1_styles,
    },

    h2_black: {
      fontWeight: 900,
      ...h2_styles,
    },
    h2_bold: {
      fontWeight: 700,
      ...h2_styles,
    },
    h2_medium: {
      fontWeight: 500,
      ...h2_styles,
    },
    h2_regular: {
      fontWeight: 400,
      ...h2_styles,
    },

    h3_black: {
      fontWeight: 900,
      ...h3_styles,
    },
    h3_bold: {
      fontWeight: 700,
      ...h3_styles,
    },
    h3_medium: {
      fontWeight: 500,
      ...h3_styles,
    },
    h3_regular: {
      fontWeight: 400,
      ...h3_styles,
    },

    h4_black: {
      fontWeight: 900,
      ...h4_styles,
    },
    h4_bold: {
      fontWeight: 700,
      ...h4_styles,
    },
    h4_medium: {
      fontWeight: 500,
      ...h4_styles,
    },
    h4_regular: {
      fontWeight: 400,
      ...h4_styles,
    },

    p1_black: {
      fontWeight: 900,
      ...p1_styles,
    },
    p1_bold: {
      fontWeight: 700,
      ...p1_styles,
    },
    p1_medium: {
      fontWeight: 500,
      ...p1_styles,
    },
    p1_regular: {
      fontWeight: 400,
      ...p1_styles,
    },

    p2_black: {
      fontWeight: 900,
      ...p2_styles,
    },
    p2_bold: {
      fontWeight: 700,
      ...p2_styles,
    },
    p2_medium: {
      fontWeight: 500,
      ...p2_styles,
    },
    p2_regular: {
      fontWeight: 400,
      ...p2_styles,
    },

    p3_black: {
      fontWeight: 900,
      ...p3_styles,
    },
    p3_bold: {
      fontWeight: 700,
      ...p3_styles,
    },
    p3_medium: {
      fontWeight: 500,
      ...p3_styles,
    },
    p3_regular: {
      fontWeight: 400,
      ...p3_styles,
    },

    p4_black: {
      fontWeight: 900,
      ...p4_styles,
    },
    p4_bold: {
      fontWeight: 700,
      ...p4_styles,
    },
    p4_medium: {
      fontWeight: 500,
      ...p4_styles,
    },
    p4_regular: {
      fontWeight: 400,
      ...p4_styles,
    },

    c1_black: {
      fontWeight: 900,
      ...c1_styles,
    },
    c1_bold: {
      fontWeight: 700,
      ...c1_styles,
    },
    c1_medium: {
      fontWeight: 500,
      ...c1_styles,
    },
    c1_regular: {
      fontWeight: 400,
      ...c1_styles,
    },

    c2_black: {
      fontWeight: 900,
      ...c2_styles,
    },
    c2_bold: {
      fontWeight: 700,
      ...c2_styles,
    },
    c2_medium: {
      fontWeight: 500,
      ...c2_styles,
    },
    c2_regular: {
      fontWeight: 400,
      ...c2_styles,
    },
  },
  mtsColor: {
    red: "#FF0032",
    base: {
      critical: "#E53935",
      warning: "#FF8F00",
      success: "#4CAF50",
      idle: "#035CC3",
      day: "#FF6485",
      night: "#36A2EB",
      plum: "#6d2d79",
      rockBlueTransparent: "#BCC3D080",
      darkNight: "#061A2F",
      blueberry: "#0097FD",
    },
    grey: {
      greyLight: "#e2e5eb",
      greyMedium: "#969fa8",
      greyDark: "#626c77",
      greyClay: "#BCC3D0",
      greyDirty: "rgba(188, 195, 208, 0.5)",
    },
    badge: {
      critical: "rgba(229, 57, 53, 0.08)",
      warning: "rgba(255, 143, 0, 0.08)",
      success: "rgba(76, 175, 80, 0.08)",
      idle: "rgba(3, 92, 195, 0.08)",
      plum: "rgba(109, 45, 121, 0.08)",
      grey: "rgba(97, 97, 97, 0.08)",
      dark: "rgba(0, 0, 0, 0.08)",
    },
    accent: {
      positive: {
        lightMode: "#26CD58",
        darkMode: "#74DF8B",
      },
      warning: {
        lightMode: "#FAC031",
        darkMode: "#FAD67D",
      },
      negative: {
        lightMode: "#F95721",
        darkMode: "#FA8A64",
      },
      active: {
        lightMode: "#007CFF",
        darkMode: "#007CFF",
      },
    },
    background: {
      primary: {
        lightMode: "#F9F9F9",
        darkMode: "#000000",
      },
      primaryElevated: {
        lightMode: "#FFFFFF",
        darkMode: "#000000",
      },
      modal: {
        lightMode: "#FFFFFF",
        darkMode: "#2C3135",
      },
      stroke: {
        lightMode: "#BCC3D07F",
        darkMode: "#7F8C9959",
      },
      secondary: {
        lightMode: "#F2F3F7",
        darkMode: "#1D2023",
      },
      secondaryElevated: {
        lightMode: "#FFFFFF",
        darkMode: "#2C3135",
      },
      inverted: {
        lightMode: "#1D2023",
        darkMode: "#FFFFFF",
      },
      overlay: {
        lightMode: "#1D202366",
        darkMode: "#1D202399",
      },
      hover: {
        lightMode: "#BCC3D03F",
        darkMode: "#F2F3F733",
      },
    },
    text: {
      headline: {
        lightMode: "#000000",
        darkMode: "#FFFFFF",
      },
      primary: {
        lightMode: "#1D2023",
        darkMode: "#FAFAFA",
      },
      secondary: {
        lightMode: "#626C77",
        darkMode: "#969FA8",
      },
      tertiary: {
        lightMode: "#969FA8",
        darkMode: "#626C77",
      },
      inverted: {
        lightMode: "#FFFFFF",
        darkMode: "#000000",
      },
      positive: {
        lightMode: "#12B23F",
        darkMode: "#74DF8B",
      },
      negative: {
        lightMode: "#EB4A13",
        darkMode: "#FFA080",
      },
      primaryLink: {
        lightMode: "#007CFF",
        darkMode: "#FFA080",
      },
      secondaryLink: {
        lightMode: "#969FA8",
        darkMode: "#626C77",
      },
    },
    controls: {
      secondaryActive: {
        lightMode: "#1D2023",
        darkMode: "#FFFFFF",
      },
      tertiaryActive: {
        lightMode: "#F2F3F7",
        darkMode: "#7F8C99",
      },
      inactive: {
        lightMode: "#BCC3D07F",
        darkMode: "#7F8C9959",
      },
    },
    icons: {
      primary: {
        lightMode: "#000000",
        darkMode: "#FFFFFF",
      },
      secondary: {
        lightMode: "#BBC1C7",
        darkMode: "#BBC1C7",
      },
      tertiary: {
        lightMode: "#BBC1C7",
        darkMode: "#626C77",
      },
    },
  },
});

export const rangeToColor = {
  blue: theme.mtsColor.base.idle,
  blueBg: theme.mtsColor.badge.idle,
  black: theme.mtsColor.background.primary.darkMode,
  blackBg: theme.mtsColor.badge.dark,
  green: theme.mtsColor.base.success,
  greenBg: theme.mtsColor.badge.success,
  red: theme.mtsColor.base.critical,
  redBg: theme.mtsColor.badge.critical,
  yellow: theme.mtsColor.base.warning,
  yellowBg: theme.mtsColor.badge.warning,
  plum: theme.mtsColor.base.plum,
  plumBg: theme.mtsColor.badge.plum,
  grey: theme.mtsColor.grey.greyDark,
  greyBg: theme.mtsColor.badge.grey,
};

export const statusToColor = {
  Высокая: theme?.mtsColor.base.critical,
  Средняя: theme?.mtsColor.base.warning,
  Низкая: theme?.mtsColor.base.success,
  Фрод: theme?.mtsColor.background.primary.darkMode,
};

export default theme;
