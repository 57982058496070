import styled from "@emotion/styled";
import { device } from "theme";

export const SCard = styled.div<{ isShort?: boolean }>`
  text-align: left;
  padding: 49px 32px;

  @media ${device.miniTabletPlus} {
    &:first-child {
      padding-left: 0;
    }
    &:nth-child(4n) {
      padding-left: 0;
    }
  }

  @media ${device.miniTabletNeg} {
    background-color: ${(props) => props.theme.mtsColor.background.primaryElevated.lightMode};
    border-radius: 20px;
    padding: 24px 20px;
  }
`;

export const SIcon = styled.span`
  display: inline-block;
  max-height: 56px;
  margin-bottom: 20px;
  width: auto;
`;

export const SDescript = styled.p`
  padding-top: 8px;
`;

export const SNumTitle = styled.h2`
  text-align: left;
  margin-bottom: 16px;
  & .MuiTypography-root {
    color: ${(props) => props.theme.mtsColor.red};
  }
`;
