import { useState } from "react";
import { isEmpty } from "lodash";

import { useBreakpoint } from "hooks/useBreakpoint";
import { Spacer } from "components/spacer/spacer.component";
import { FormatPrice } from "../shopCard/utils";
import { ShopButton } from "../shopCard/shopCards.component";
import { IShopIndividualItem } from "interface/common";
import { CustomTypography } from "components/customTypography/custom-typography";
import { SPrice, SPromoTags } from "../shopCard/styles";
import { SMobBtns, SRow, SSticky, STabBody, STabHead, STabItem, STags } from "./styles";

interface IProp {
  product: IShopIndividualItem;
}

export const InfoRight: React.FC<IProp> = ({ product }) => {
  const { isTablet } = useBreakpoint();
  const tags = [product.category, product.type, product.conn_type].filter((a) => a !== null);

  return (
    <>
      <STags>
        {tags.map((t, i) => (
          <CustomTypography key={i} variant="c1_regular" font="comp">
            {t}
          </CustomTypography>
        ))}
      </STags>
      <Spacer value="16px" />
      <h1>
        <CustomTypography variant={isTablet() ? "p3_medium" : "h1_medium"} font="comp">
          {product.name}
        </CustomTypography>
      </h1>
      <Spacer value="16px" />
      {!isEmpty(product.promotions) ? (
        <>
          <SPromoTags>
            {product.promotions.map((promo) => (
              <div key={promo.promotion_id}>
                <CustomTypography font="wide">{promo.promotion_bubble}</CustomTypography>
              </div>
            ))}
          </SPromoTags>
        </>
      ) : null}
      <Spacer value={isTablet() ? "8px" : "24px"} />
      <CustomTypography
        variant={isTablet() ? "c1_regular" : "p4_regular"}
        styles={isTablet() ? { lineHeight: 1 } : {}}
      >
        {product.desc}
      </CustomTypography>
      {isTablet() ? null : (
        <>
          <Spacer value="48px" />
          <SPrice>
            <CustomTypography variant="h1_bold" font="comp">
              <FormatPrice val={product.price} variant="h1_bold" font="comp" />
            </CustomTypography>
          </SPrice>
          <Spacer value="32px" />
          <ShopButton id={product.id} style={{ minWidth: 336 }} />
        </>
      )}
    </>
  );
};

const titleByTab = {
  characteristics: "Характеристики",
  features: "Особенности",
  docs: "Документация",
};
const tabHeads = ["characteristics", "features", "docs"] as const;

export const InfoTabs: React.FC<IProp> = ({ product }) => {
  const { isTablet } = useBreakpoint();
  const [active, setActive] = useState<typeof tabHeads[number]>("characteristics");
  return (
    <>
      <STabHead>
        {tabHeads.map((h, i) => (
          <STabItem key={i} onClick={() => setActive(h)} isActive={active === h}>
            <h2>{titleByTab[h]}</h2>
          </STabItem>
        ))}
      </STabHead>
      <Spacer value={isTablet() ? "29px" : "48px"} />
      {product[active] != null ? (
        <STabBody>
          {Object.entries(product[active]!).map(([key, val]) => (
            <SRow key={`${key}_${val}`}>
              <div>{key}</div>
              <div>
                {active === "docs" ? (
                  <a href={val as string} target="_blank" rel="noopener noreferrer">
                    Скачать файл
                  </a>
                ) : (
                  (val as string)
                )}
              </div>
            </SRow>
          ))}
        </STabBody>
      ) : (
        <CustomTypography variant={isTablet() ? "c1_regular" : "p4_regular"}>
          Нет данных
        </CustomTypography>
      )}
      {isTablet() ? (
        <>
          <Spacer value="24px" />
          <SSticky>
            <SMobBtns>
              <CustomTypography variant="p2_bold" font="wide">
                <FormatPrice val={product.price} variant="p2_bold" font="wide" />
              </CustomTypography>
              <ShopButton id={product.id} style={{ minWidth: 180 }} />
            </SMobBtns>
          </SSticky>
        </>
      ) : null}
    </>
  );
};
