import React from "react";
import { styled } from "@mui/material/styles";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionIcon from "../accordion-icon";
import { IAccordionCustom } from "interface/common";
import { CustomTypography } from "components/customTypography/custom-typography";
import { SectionTitle } from "components/section-title/section-title";

import useStyles, { SCustomAccord } from "./styles";

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<AccordionIcon />} {...props} />
))(({ theme }) => ({
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(0)",
  },
}));

interface AccordionProps {
  data: IAccordionCustom[];
}

export const AccordionCustom = (props: AccordionProps) => {
  const { data } = props;
  const [expanded, setExpanded] = React.useState<string | false>("");

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const classes = useStyles();

  return (
    <>
      <SectionTitle title="Вопросы и ответы" />
      <SCustomAccord>
        {data.map((item, index) => (
          <Accordion
            key={item.title}
            square
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            className={classes.accordion}
          >
            <AccordionSummary
              expandIcon={
                expanded === `panel${index}` ? <AccordionIcon isActive={true} /> : <AccordionIcon />
              }
              aria-controls={`panel${index}d-content`}
              id={`panel${index}d-header`}
              className={classes.accordionSummary}
            >
              <h3>
                <CustomTypography variant="h4_medium" font="comp">
                  {item.title}
                </CustomTypography>
              </h3>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {item.text ? (
                <CustomTypography variant="p3_regular">{item.text}</CustomTypography>
              ) : null}
              {item.pdf ? (
                <a className={classes.link} href={item.pdf.link} target="_blank" rel="noreferrer">
                  {item.pdf.icon}{" "}
                  <CustomTypography variant="p3_regular">{item.pdf.text}</CustomTypography>{" "}
                  <CustomTypography variant="p3_regular" className={classes.pdfSize}>
                    (pdf, {item.pdf.size})
                  </CustomTypography>
                </a>
              ) : null}
              {item.list ? (
                <ul>
                  {item.list.map((e, i) => (
                    <li key={i}>
                      <CustomTypography variant="p3_regular">{e}</CustomTypography>
                    </li>
                  ))}
                </ul>
              ) : null}
            </AccordionDetails>
          </Accordion>
        ))}
      </SCustomAccord>
    </>
  );
};
