import styled from "@emotion/styled";
import { CustomTypography } from "components/customTypography/custom-typography";

export const SRuble = styled(CustomTypography)`
  visibility: hidden;

  &::before {
    content: "₽";
    visibility: visible;
  }

  & > span {
    width: 0;
    display: inline-block;
  }
`;
