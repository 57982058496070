import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useBreakpoint } from "hooks/useBreakpoint";
import { useShopCheckout } from "hooks/hooks";
import { EPriceType } from "interface/common";
import { Back } from "components/back/back.component";
import { SectionTitle } from "components/section-title/section-title";
import { Spacer } from "components/spacer/spacer.component";
import { MTSModal } from "components/mts-modal/mts-modal";
import { FormBankInfo } from "components/forms/shop-checkout/form-bank-info";
import { FormUserInfo } from "components/forms/shop-checkout/form-user-info.tsx";
import { FormFeedbackInfo } from "components/forms/shop-checkout/form-feedback-info";
import { FormPlaceOrder } from "components/forms/shop-checkout/form-place-order";
import { FormPromocode } from "components/forms/shop-checkout/form-promocode";
import { FormatPrice } from "components/shop/shopCard/utils";
import { NotificationBlock } from "components/notification-block/notification-block";
import { composeUserDefault } from "components/forms/shop-checkout/utils";
import { CustomTypography } from "components/customTypography/custom-typography";
import { RubleSymbol } from "components/ruble-simbol/ruble.component";
import { USER_UL } from "components/forms/shop-checkout/const";
import {
  emptyPriceDisclaim,
  promoCategory,
  renderPriceProduct,
  resultPriceOrder,
} from "components/shop/const";
import { ItemInfo } from "./subComponent";
import {
  SBtns,
  SCheque,
  SContain,
  SContainer,
  SDisclaime,
  SInfo,
  SMain,
  SOrder,
  SPrice,
  SPriceOld,
  SProdItem,
  SProdList,
  SSale,
  STotal,
  SWrap,
} from "./styles";

export interface ILocationState {
  id: number;
  name: string;
  category: string;
  qty: number;
  price: number;
  price_type?: EPriceType;
}

export const ShopCheckout = () => {
  const { isTablet } = useBreakpoint();
  const [showRequisModal, setRequisModal] = useState(false);
  const [showCustomerModal, setCustomerModal] = useState(false);
  const [showContactsModal, setContactsModal] = useState(false);
  const [promocode, setPromocode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [isOrderSucces, setIsOrderSucces] = useState(false);
  const [isInt, setIsInt] = useState(false);
  const [userId, setUserId] = useState<number | null>(null);

  const checkoutData = useShopCheckout();
  const dataBankInfo = checkoutData?.data?.bank_info;
  const dataFeedbackInfo = checkoutData?.data?.feedback_info;
  const dataInformation = checkoutData?.data?.information;

  const locationState = useLocation().state as ILocationState[];

  const totalPrice = resultPriceOrder(locationState);

  const saveDiscountVal = (val: number) => {
    setDiscount(val);
  };

  const toggleRequisiteModal = () => {
    setRequisModal((s) => !s);
  };

  const toggleCustomerModal = () => {
    setCustomerModal((s) => !s);
  };

  const toggleContactsModal = () => {
    setContactsModal((s) => !s);
  };

  const setPromocodeVal = (val: string) => {
    setPromocode(val);
  };

  const submitOrderRes = (val: boolean) => {
    setIsOrderSucces(val);
  };

  const calcSumSales = isInt ? (Number(totalPrice) * discount) / 100 : null;

  useEffect(() => {
    setUserId(checkoutData?.data?.user_id ?? null);
  }, [setUserId, checkoutData?.data]);

  useEffect(() => {
    setIsInt(typeof totalPrice === "number");
  }, [totalPrice]);

  return isOrderSucces ? (
    <>
      <Spacer value="185px" />
      <NotificationBlock type="success" />
      <Spacer value="120px" />
    </>
  ) : (
    <>
      <SMain>
        <SContainer>
          <Back name="Вернуться в корзину" />
          <Spacer value={isTablet() ? "24px" : "48px"} />
          <SectionTitle title="Оформление заказа" />
          <SWrap>
            <SInfo>
              <ItemInfo
                title="Информация о заказчике"
                data={dataInformation}
                isEmpty={!dataInformation?.tenant_name}
                onOpenForm={toggleCustomerModal}
                typeCard="user_info"
                isUrik={checkoutData?.data?.type_user === USER_UL}
              />
              <ItemInfo
                title="Банковские реквизиты"
                data={dataBankInfo}
                isEmpty={!dataBankInfo?.bank_name}
                onOpenForm={toggleRequisiteModal}
                typeCard="bank_info"
              />
              <ItemInfo
                title="Контакты для обратной связи"
                data={dataFeedbackInfo}
                isEmpty={!dataFeedbackInfo?.name}
                onOpenForm={toggleContactsModal}
                typeCard="feedback_info"
              />
            </SInfo>
            <SOrder>
              <SContain>
                <CustomTypography variant={isTablet() ? "p3_medium" : "h4_medium"} font="comp">
                  Состав заказа
                </CustomTypography>
                <Spacer value={isTablet() ? "12px" : "32px"} />
                {locationState ? (
                  <SProdList>
                    {locationState.map((item) => (
                      <SProdItem key={item.name}>
                        <div>
                          <CustomTypography variant="c1_regular" font="comp">
                            {item.name}
                            <CustomTypography variant="c1_regular" font="comp">
                              {item.qty} шт
                            </CustomTypography>
                          </CustomTypography>
                        </div>
                        <CustomTypography variant="c1_medium" font="comp">
                          {item.category === promoCategory ? (
                            <>
                              0<RubleSymbol variant="c1_medium" font="comp" />*
                            </>
                          ) : (
                            renderPriceProduct(item.price, false)
                          )}
                        </CustomTypography>
                      </SProdItem>
                    ))}
                  </SProdList>
                ) : (
                  <CustomTypography variant="c1_regular">Корзина пуста</CustomTypography>
                )}
              </SContain>
              <SCheque>
                <FormPromocode saveDiscountVal={saveDiscountVal} setPromocode={setPromocodeVal} />
                <SSale>
                  <CustomTypography variant="c1_regular">Скидка</CustomTypography>
                  <CustomTypography variant="c1_medium">
                    {calcSumSales ? (
                      <FormatPrice val={calcSumSales} variant="c1_medium" />
                    ) : discount ? (
                      `${discount} %`
                    ) : (
                      <>
                        0 <RubleSymbol variant="c1_medium" />
                      </>
                    )}
                  </CustomTypography>
                </SSale>
                <STotal>
                  <CustomTypography variant="c1_regular">ИТОГО</CustomTypography>
                  <SPrice>
                    {discount && isInt ? (
                      <SPriceOld>
                        <CustomTypography variant="c1_regular">
                          <FormatPrice val={totalPrice} variant="c1_regular" />
                        </CustomTypography>
                      </SPriceOld>
                    ) : null}
                    <div>
                      <CustomTypography variant="h4_bold" font="wide">
                        {isInt && calcSumSales ? (
                          <FormatPrice
                            val={(Number(totalPrice) - calcSumSales).toFixed(2)}
                            variant="h4_bold"
                            font="wide"
                          />
                        ) : isInt ? (
                          <FormatPrice val={totalPrice} variant="h4_bold" font="wide" />
                        ) : (
                          `${totalPrice}*`
                        )}
                      </CustomTypography>
                    </div>
                  </SPrice>
                </STotal>
              </SCheque>
              <SBtns>
                <FormPlaceOrder
                  isUserDataFull={
                    Boolean(dataInformation?.tenant_name) &&
                    Boolean(dataBankInfo?.bank_name) &&
                    Boolean(dataFeedbackInfo?.name)
                  }
                  promocode={promocode}
                  cartProducts={locationState}
                  setOrderRes={submitOrderRes}
                  userId={userId}
                />
              </SBtns>
              {!isInt ? (
                <SDisclaime variant="c2_regular" font="comp">
                  {emptyPriceDisclaim}
                </SDisclaime>
              ) : null}
            </SOrder>
          </SWrap>
        </SContainer>
      </SMain>
      <MTSModal open={showCustomerModal} close={toggleCustomerModal} maxWidth={672}>
        <FormUserInfo
          onClose={toggleCustomerModal}
          formDefault={composeUserDefault(
            checkoutData?.data?.information,
            checkoutData?.data?.type_user,
          )}
        />
      </MTSModal>
      <MTSModal open={showRequisModal} close={toggleRequisiteModal} maxWidth={672}>
        <FormBankInfo onClose={toggleRequisiteModal} data={dataBankInfo} />
      </MTSModal>
      <MTSModal open={showContactsModal} close={toggleContactsModal} maxWidth={672}>
        <FormFeedbackInfo onClose={toggleContactsModal} data={checkoutData?.data} />
      </MTSModal>
    </>
  );
};
