import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

import { ContextSettings } from "context/context-settings";
import { useCheckCart, useShopCatalog } from "hooks/hooks";
import { useBreakpoint } from "hooks/useBreakpoint";
import { ROUTE_SHOP, ROUTE_SHOP_CART } from "stream-constants/route-constants";
import { Back } from "components/back/back.component";
import { Spacer } from "components/spacer/spacer.component";
import { CartProductCard, CartServiceCard, Total } from "components/shop/shopCart/cartItems";
import { NotificationBlock } from "components/notification-block/notification-block";
import { SectionTitle } from "components/section-title/section-title";
import { Loader } from "components/spinner";
import { Toast } from "components/toast/toast";
import { resultPrice, serviceCategory } from "components/shop/const";
import { ItemsSlider } from "components/shop/shopSlider/itemsSlider";
import { CustomTypography } from "components/customTypography/custom-typography";
import { domain, makePayload, RenderDisclaim, seo } from "./const";
import { SBox, SDisclaim, SGrid } from "./styles";

export const ShopCart = () => {
  const { isTablet, isDesktop } = useBreakpoint();
  const { cart } = useContext(ContextSettings);

  const { data: catalog = [], isLoading, isError } = useShopCatalog();
  const isCartContainExistingGoods = Object.keys(cart)?.some(
    (a) => catalog.find((f) => f.id === parseInt(a)) != null,
  );
  const { data: items = [] } = useCheckCart(makePayload(cart), isCartContainExistingGoods);

  const uniqPromoDisclamers = [
    ...new Set(items.map((item) => (item.promo_disclamer ? item.promo_disclamer : ""))),
  ].filter(Boolean);

  const services = items.filter((a) => a.category === serviceCategory);
  const staticItems = catalog
    ?.filter((a) => Boolean(cart[a.id]) && !cart[a.id]?.hasOwnProperty("promo_visible"))
    .filter((a) => services?.find((f) => f.name === a.name) == null);
  const combo = [...(staticItems ?? []), ...services];
  const totalPrice = resultPrice(combo, cart);

  useEffect(() => {
    if (isError)
      toast(<Toast title="Ошибка запроса каталога" isError />, {
        progress: undefined,
        autoClose: 1500,
        hideProgressBar: true,
      });
  }, [isError]);

  return (
    <>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <link rel="canonical" href={`${domain}/${ROUTE_SHOP_CART}`} />
      </Helmet>
      <SBox>
        {!isCartContainExistingGoods ? (
          <section>
            <Back link={`/${ROUTE_SHOP}`} />
            <Spacer value={isTablet() ? "28px" : "48px"} />
            <h1>
              <CustomTypography variant={isTablet() ? "h3_medium" : "h1_medium"} font="wide">
                Корзина
              </CustomTypography>
              <Spacer value="48px" />
            </h1>
            <NotificationBlock type="cart" />
          </section>
        ) : (
          <section>
            <Back link={`/${ROUTE_SHOP}`} />
            <Spacer value={isTablet() ? "28px" : "48px"} />
            <h1>
              <CustomTypography variant={isTablet() ? "h3_medium" : "h1_medium"} font="wide">
                Корзина
              </CustomTypography>
              <Spacer value="48px" />
            </h1>
            <SGrid>
              <div>
                {staticItems?.map((a) => (
                  <CartProductCard
                    product={a}
                    quantity={cart[a.id]?.qty}
                    key={`device_${a.id}`}
                    type={a.category === serviceCategory ? "service" : "cart"}
                  />
                ))}
                {services?.map((b) => (
                  <CartServiceCard key={`service_${b.id}`} product={b} />
                ))}
                {uniqPromoDisclamers.map((string, index) => (
                  <SDisclaim key={index}>{RenderDisclaim(string)}</SDisclaim>
                ))}
              </div>
              {isDesktop() ? <Total combo={combo} totalPrice={totalPrice} /> : null}
            </SGrid>
            {isTablet() ? (
              <>
                <Spacer value="24px" />
                <SectionTitle title="Возможно вас заинтересует" />
                <ItemsSlider
                  items={catalog?.filter((a) => a.category !== serviceCategory && !a.hide_flag)}
                  isLoading={isLoading}
                />
              </>
            ) : null}
            {!isDesktop() ? (
              <>
                <Spacer value="24px" />
                <Total combo={combo} totalPrice={totalPrice} />{" "}
              </>
            ) : null}
          </section>
        )}
        <Spacer value={isDesktop() ? "120px" : "80px"} />
        {isLoading ? (
          <>
            <Loader />
          </>
        ) : null}
      </SBox>
    </>
  );
};
