export const citiesGeo = [
  { name: "Абакан", lat: 53.721152, lng: 91.442396 },
  { name: "Архангельск", lat: 64.539911, lng: 40.515762 },
  { name: "Арамиль", lat: 56.700276, lng: 60.828647 },
  { name: "Благовещенск", lat: 50.249266, lng: 127.553278 },
  { name: "Барнаул", lat: 53.346785, lng: 83.776856 },
  { name: "Владивосток", lat: 55.355198, lng: 86.086847 },
  { name: "Волгоград", lat: 48.707067, lng: 44.516975 },
  { name: "Воронеж", lat: 51.67204, lng: 39.184302 },
  { name: "Горно-Алтайск", lat: 51.957804, lng: 85.960634 },
  { name: "Елабуга", lat: 55.757496, lng: 52.053939 },
  { name: "Екатеринбург", lat: 56.838011, lng: 60.597474 },
  { name: "Иваново", lat: 56.999799, lng: 40.973014 },
  { name: "Ижевск", lat: 56.845096, lng: 53.188089 },
  { name: "Иркутск", lat: 52.289588, lng: 104.280606 },
  { name: "Казань", lat: 55.796127, lng: 49.106414 },
  { name: "Калининград", lat: 54.710162, lng: 20.510137 },
  { name: "Калуга", lat: 54.513678, lng: 36.261341 },
  { name: "Кемерово", lat: 55.355198, lng: 86.086847 },
  { name: "Киров", lat: 58.603595, lng: 49.668023 },
  { name: "Клин", lat: 56.341692, lng: 36.743542 },
  { name: "Кострома", lat: 57.767821, lng: 40.926739 },
  { name: "Краснокаменск", lat: 50.098683, lng: 118.034146 },
  { name: "Краснодар", lat: 45.03547, lng: 38.975313 },
  { name: "Красноярск", lat: 56.010543, lng: 92.852581 },
  { name: "Курган", lat: 55.706383, lng: 67.879696 },
  { name: "Курск", lat: 51.730846, lng: 36.193015 },
  { name: "Липецк", lat: 52.608826, lng: 39.599229 },
  { name: "Махачкала", lat: 42.9831, lng: 47.504745 },
  { name: "Москва", lat: 55.755864, lng: 37.617698 },
  { name: "Мурманск", lat: 68.964319, lng: 33.048633 },
  { name: "Муром", lat: 55.563235, lng: 42.023196 },
  { name: "Нижний Новгород", lat: 56.326797, lng: 44.006516 },
  { name: "Новокузнецк", lat: 53.79429, lng: 87.214494 },
  { name: "Новосибирск", lat: 55.030204, lng: 82.92043 },
  { name: "Нолинск", lat: 57.554446, lng: 49.937192 },
  { name: "Омск", lat: 54.984822, lng: 73.367418 },
  { name: "Оренбург", lat: 51.768205, lng: 55.097 },
  { name: "Орск", lat: 51.2293, lng: 58.474675 },
  { name: "Пенза", lat: 53.195042, lng: 45.018316 },
  { name: "Пермь", lat: 58.028966, lng: 56.231399 },
  { name: "Петрозаводск", lat: 61.789113, lng: 34.346604 },
  { name: "Петропавловск-Камчатский", lat: 53.052157, lng: 158.624103 },
  { name: "Пятигорск", lat: 44.039802, lng: 43.070643 },
  { name: "Раменское", lat: 55.550243, lng: 38.277468 },
  { name: "Рязань", lat: 54.625835, lng: 39.829439 },
  { name: "Салехард", lat: 66.529866, lng: 66.614507 },
  { name: "Самара", lat: 53.195878, lng: 50.100202 },
  { name: "Санкт-Петербург", lat: 59.938784, lng: 30.314997 },
  { name: "Саранск", lat: 54.183076, lng: 45.188131 },
  { name: "Саратов", lat: 51.533338, lng: 46.034176 },
  { name: "Севастополь", lat: 44.616609, lng: 33.525358 },
  { name: "Симферополь", lat: 44.948242, lng: 34.100159 },
  { name: "Смоленск", lat: 54.78617, lng: 31.81248 },
  { name: "Ставрополь", lat: 45.043317, lng: 41.96911 },
  { name: "Сызрань", lat: 53.16528, lng: 48.47416 },
  { name: "Тамбов", lat: 52.721295, lng: 41.45275 },
  { name: "Тверь", lat: 56.85853, lng: 35.91168 },
  { name: "Тула", lat: 54.193122, lng: 37.617348 },
  { name: "Тюмень", lat: 57.152985, lng: 65.541227 },
  { name: "Уфа", lat: 54.735152, lng: 55.958736 },
  { name: "Хабаровск", lat: 48.481244, lng: 135.071352 },
  { name: "Ханты-Мансийск", lat: 61.003184, lng: 69.018911 },
  { name: "Чебоксары", lat: 56.139918, lng: 47.247728 },
  { name: "Челябинск", lat: 55.159902, lng: 61.402554 },
  { name: "Черкесск", lat: 44.21493, lng: 42.05891 },
  { name: "Чита", lat: 52.033635, lng: 113.501049 },
  { name: "Шадринск", lat: 56.08778, lng: 63.61236 },
  { name: "Шумерля", lat: 55.487321, lng: 46.418795 },
  { name: "Щёкино", lat: 54.001574, lng: 37.518165 },
  { name: "Энгельс", lat: 51.485485, lng: 46.120149 },
  { name: "Южно-Сахалинск", lat: 46.959134, lng: 142.733741 },
  { name: "Якутск", lat: 62.027221, lng: 129.732178 },
  { name: "Ярославль", lat: 57.628446, lng: 39.871859 },
];

export const info: Array<{ title: string; description: string }> = [
  {
    title: "24 000+",
    description: "Заведённых приборов учёта в облачное АСКУЭ",
  },
  {
    title: "450 000+",
    description: "Подключено к аналитическому модулю",
  },
  {
    title: "50+",
    description: "Городов поставок",
  },
];
