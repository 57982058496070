import { CustomTypography } from "components/customTypography/custom-typography";
import { useBreakpoint } from "hooks/useBreakpoint";
import { SSectionTitle } from "./styles";

interface SectionTitleProps {
  title: string;
  isWhite?: boolean;
  styles?: React.CSSProperties;
}

export const SectionTitle = (props: SectionTitleProps) => {
  const { title, isWhite, styles } = props;
  const { isTablet } = useBreakpoint();

  return (
    <SSectionTitle isWhite={isWhite} style={styles}>
      <CustomTypography variant={isTablet() ? "h2_medium" : "h1_medium"} font="wide">
        {title}
      </CustomTypography>
    </SSectionTitle>
  );
};
