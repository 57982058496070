import { Helmet } from "react-helmet";

import { CustomTypography } from "components/customTypography/custom-typography";
import { ROUTE_OFFER_LICENSE } from "stream-constants/route-constants";
import { Spacer } from "components/spacer/spacer.component";
import { useBreakpoint } from "hooks/useBreakpoint";

import { domain } from "pages/shopCart/const";
import { pageContent, pageTitle, seo } from "./const";
import { SArticle, SContainer, SMain, SParag, STable } from "./styles";

export const OfferLicense = () => {
  const { isTablet } = useBreakpoint();
  return (
    <>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <link rel="canonical" href={`${domain}/${ROUTE_OFFER_LICENSE}`} />
      </Helmet>
      <SMain>
        <SContainer>
          <SArticle>
            <h1>
              <CustomTypography variant={isTablet() ? "h3_medium" : "h1_medium"} font="wide">
                {pageTitle}
              </CustomTypography>
              <Spacer value="48px" />
            </h1>
            <div>
              <SParag>
                {pageContent.map((item) => (
                  <>
                    {item.title ? (
                      <CustomTypography variant="p3_bold" font="comp">
                        {item.title}
                      </CustomTypography>
                    ) : null}
                    {item.text.map((text, index) =>
                      text === "isTable" && item.table ? (
                        <STable key={`table_${index}`}>
                          <table>
                            <tbody>
                              {item.table.map((tds, i) => (
                                <tr key={`tr_${i}`}>
                                  {tds.map((td, indx) => (
                                    <td>{td}</td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </STable>
                      ) : (
                        <CustomTypography variant="p3_regular" font="comp">
                          <span dangerouslySetInnerHTML={{ __html: text }} />
                        </CustomTypography>
                      ),
                    )}
                  </>
                ))}
              </SParag>
            </div>
          </SArticle>
        </SContainer>
      </SMain>
    </>
  );
};
