import { useEffect, useState } from "react";
import { YMaps, Map, Placemark } from "react-yandex-maps";

import { SectionTitle } from "components/section-title/section-title";
import { Spacer } from "components/spacer/spacer.component";
import { GridInfo } from "components/grid-info/grid-info";
import { GridInfoCard } from "components/grid-info-card/grid-info-card";
import { citiesGeo, info } from "./const";
import { SContainer, SWrap } from "./styles";

import pointImg from "./images/point.svg";

interface IProps {
  title: string;
}

export const MapSection: React.FC<IProps> = ({ title }) => {
  const [coordinates, setCoordinates] = useState<number[][]>([]);

  useEffect(() => {
    const loadCoordinates = async () => {
      const coord = citiesGeo.map((city) => [city.lat, city.lng]);
      setCoordinates(coord);
    };

    loadCoordinates();
  }, []);

  return (
    <SWrap>
      <SContainer>
        <SectionTitle title={title} />
        <div>
          <GridInfo isShort>
            {info.map((item, index) => {
              return <GridInfoCard key={index} title={item.title} description={item.description} />;
            })}
          </GridInfo>
        </div>
        <Spacer value="40px" />
      </SContainer>
      <YMaps query={{ apikey: process.env.REACT_APP_YANDEX_KEY }}>
        <Map
          defaultState={{
            center: [57.992438, 56.040823],
            zoom: 5,
            width: "100%",
          }}
          options={{
            suppressMapOpenBlock: true,
            scrollZoom: false,
            minZoom: 3,
            maxZoom: 7,
          }}
          width="100%"
          height="560px"
          style={{ width: "100%", height: "500px" }}
        >
          {coordinates.map((coordinate, index) => (
            <Placemark
              key={index}
              geometry={coordinate}
              options={{
                iconLayout: "default#image",
                iconImageHref: pointImg,
                iconImageSize: [52, 52],
                iconImageOffset: [-26, -65],
              }}
            />
          ))}
        </Map>
      </YMaps>
    </SWrap>
  );
};
