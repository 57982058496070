import { useContext } from "react";
import { Helmet } from "react-helmet";

import { MTSButton } from "components/mts-button/mts-button";
import { SectionTitle } from "components/section-title/section-title";
import { Spacer } from "components/spacer/spacer.component";
import { ProductCard } from "components/product-card/product-card";
import { GridInfo } from "components/grid-info/grid-info";
import { GridInfoCard } from "components/grid-info-card/grid-info-card";
import { AccordionCustom } from "components/accordion-custom/accordion-custom";
import { ContextSettings } from "context/context-settings";
import IntroSection from "components/intro-section";
import { MapSection } from "components/map-section/mapSection";

import { accordionInfo, advantageData, productsCard, seo } from "./const";
import { domain } from "pages/shopCart/const";
import { SAdvantage, SContainer, SGrid, SMain, SSection } from "./styles";

import bgImg from "./images/main-landing-bg.png";

export const Landing = () => {
  const context = useContext(ContextSettings);

  return (
    <>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <link rel="canonical" href={domain} />
      </Helmet>
      <SMain>
        <IntroSection
          title="ENERGYTOOL"
          text="Лучшие решения на рынке энергетических услуг"
          subtext="Комплекс программных решений, которые помогут оптимизировать энергетические расходы вашей организации, выявить подозрительную активность абонентов и автоматизировать процессы сбора и анализа данных"
          withConsultBtn={true}
          withLoginBtn={true}
          bgImg={bgImg}
          isFullScreen
        />
        <SSection>
          <SContainer>
            <SectionTitle title="Что мы предлагаем?" />
            <SGrid>
              {productsCard.map((item, index) => {
                return (
                  <ProductCard
                    key={index}
                    title={item.title}
                    description={item.description}
                    nameBtn={item.nameBtn}
                    isCallbackBlock={item.isCallbackBlock}
                    img={item.img}
                    link={item.link}
                  />
                );
              })}
            </SGrid>
          </SContainer>
        </SSection>
        <MapSection title="География реализованных проектов" />
        <SAdvantage>
          <SContainer>
            <SectionTitle title="Почему стоит работать с нами?" />
            <div>
              <GridInfo>
                {advantageData.map((item, index) => {
                  return (
                    <GridInfoCard
                      key={index}
                      icon={item.icon}
                      title={item.title}
                      description={item.description}
                    />
                  );
                })}
              </GridInfo>
            </div>
            <Spacer value="48px" />
            <div>
              <MTSButton
                size="L"
                variant="dark_blue"
                onClick={() => context.changeStateFormConsultation(true)}
              >
                Связаться с нами
              </MTSButton>
            </div>
          </SContainer>
        </SAdvantage>
        <SSection>
          <SContainer>
            <AccordionCustom data={accordionInfo} />
          </SContainer>
        </SSection>
      </SMain>
    </>
  );
};
