import styled from "@emotion/styled/macro";
import { device } from "theme";

export const SCard = styled.div<{ isCallbackBlock?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 45px 32px 40px;
  min-height: 256px;
  position: relative;
  border-radius: 8px;
  text-align: left;

  ${(props) =>
    props.isCallbackBlock
      ? `
		border: 1px solid ${props.theme.mtsColor.grey.greyMedium};
		background-color: ${props.theme.mtsColor.background.secondaryElevated.lightMode};
		background: none;
		& .MuiTypography-root {
		color: ${props.theme.mtsColor.text.inverted.darkMode};
		};
		& p .MuiTypography-root {
		color: ${props.theme.mtsColor.text.secondary.lightMode};
		};
		& button {
			padding: 6px 16px;
			.MuiTypography-root {
			color: ${props.theme.mtsColor.text.inverted.lightMode};
			}
		}

		@media ${device.miniTabletNeg} {
			padding: 32px 0;
			& h3 .MuiTypography-root {
				font-size: 24px;
			};
		}
  		`
      : `
		background-color: #0C2E52;
		background: radial-gradient(circle, #0C2E52 0%, ${props.theme.mtsColor.base.darkNight} 100%);
		border: 1px solid #0C2E52;
		& h3 {
			& .MuiTypography-root {
			  color: ${props.theme.mtsColor.text.inverted.lightMode};
			}
		};

		@media ${device.tabletNeg} {
		  padding: 32px 20px;
		}
   `}

  & > a {
    text-decoration: none;
    cursor: default;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  @media ${device.miniTabletNeg} {
    min-height: auto;

    ${(props) =>
      props.isCallbackBlock
        ? `
	  	border: none;

		& a > div {
		max-width: inherit;
		width: 100%;
		}

		& h3 {
			text-align: center;
		}

		& p {
			text-align: center;
		}

		${SBtns} {
			display: block;
			text-align: center;
			& .MuiButton-root {
			  font-size: 17px;
			  line-height: 24px;
			  padding-top: 10px;
			  padding-bottom: 10px;
			  width: 100%;
			};
		}
	  `
        : ``};
  }
`;

export const SImg = styled.img`
  position: absolute;
  bottom: 24px;
  right: 32px;
  max-width: 160px;
  max-height: 160px;
  width: 25.1%;

  @media ${device.miniTabletNeg} {
    position: relative;
    height: 90px;
    width: 90px;
    bottom: inherit;
    right: inherit;
    transform: none;
    margin-bottom: 16px;
  }
`;

export const SBody = styled.div`
  max-width: 412px;
  width: 72%;

  & > h3 {
    text-align: left;
    margin-bottom: 14px;
    & .MuiTypography-root {
      @media ${device.mobileNeg} {
        font-size: 20px;
      }
    }
  }

  & > p {
    text-align: left;
    & .MuiTypography-root {
      color: #bfc7ce;

      @media ${device.mobileNeg} {
        font-size: 14px;
      }
    }
  }

  @media ${device.miniTabletNeg} {
    max-width: inherit;
    width: 100%;
  }
`;

export const SBtns = styled.div`
  display: flex;
  align-items: center;
  padding-top: 32px;

  & > a {
    text-decoration: none;
    display: inline-block;
  }

  @media ${device.miniTabletNeg} {
    display: none;
  }
`;
