import { CustomTypography } from "components/customTypography/custom-typography";
import { SCard, STitle, SImg, SImgWrap, STop, SBottom, SNum } from "./styles";

interface IntroCardProps {
  img?: string;
  title?: string;
  text?: string;
  numbered?: string | number;
}

export const IntroCard = ({ img, title, text, numbered }: IntroCardProps) => {
  return (
    <SCard>
      <STop>
        <SImgWrap>
          <SImg src={img} alt={title} />
        </SImgWrap>
        <STitle>
          <CustomTypography variant="h3_bold">{title}</CustomTypography>
        </STitle>
      </STop>
      <SBottom>
        <CustomTypography variant="p4_regular">{text}</CustomTypography>
      </SBottom>
      <SNum>
        <CustomTypography variant="promo3_black">{numbered}</CustomTypography>
      </SNum>
    </SCard>
  );
};
