import { CustomTypography } from "components/customTypography/custom-typography";
import { useBreakpoint } from "hooks/useBreakpoint";
import { SCard, SDescript, SIcon, SNumTitle } from "./styles";

interface GridInfoCardProps {
  title?: string;
  description?: string;
  icon?: any;
}

export const GridInfoCard = (props: GridInfoCardProps) => {
  const { isTablet } = useBreakpoint();
  const { icon, title, description } = props;
  const isBase = Boolean(icon);

  return (
    <SCard>
      {isBase ? (
        <>
          <SIcon>
            <img src={icon} alt={title} />
          </SIcon>
          <h3>
            <CustomTypography variant="h4_medium" font="comp">
              {title}
            </CustomTypography>
          </h3>
          <SDescript>
            <CustomTypography variant="p4_regular" font="comp">
              {description}
            </CustomTypography>
          </SDescript>
        </>
      ) : (
        <>
          <SNumTitle>
            <CustomTypography variant={isTablet() ? "promo3_bold" : "promo2_bold"} font="comp">
              {title}
            </CustomTypography>
          </SNumTitle>
          <CustomTypography variant="p3_regular" font="comp">
            {description}
          </CustomTypography>
        </>
      )}
    </SCard>
  );
};
