import { Link } from "react-router-dom";

import productImg2 from "./images/product-2.png";
import productImg3 from "./images/product-3.png";
import introCardImg1 from "./images/intro-card-img-1.png";
import introCardImg2 from "./images/intro-card-img-2.png";
import introCardImg3 from "./images/intro-card-img-3.png";
import instructionPdf from "../../files/instruction_lk.pdf";

import { ROUTE_ASKUE_PAGE, ROUTE_MINING_PAGE } from "stream-constants/route-constants";
import { IAccordionCustom } from "interface/common";
import Icon from "components/icons";
import { CustomTypography } from "components/customTypography/custom-typography";

export const productsCard: Array<{
  title: string;
  description?: string;
  nameBtn: string;
  isCallbackBlock?: boolean;
  img?: string;
  link?: string;
}> = [
  {
    title: "Выявление майнинга",
    description:
      "Наше решение поможет своевременно обнаружить подозрительную активность потенциальных майнинговых\u00A0ферм с\u00A0указанием их\u00A0расположения",
    nameBtn: "Узнать подробнее",
    isCallbackBlock: false,
    img: productImg3,
    link: ROUTE_MINING_PAGE,
  },
  {
    title: "Облачная платформа учета",
    description:
      "Облачное решение, которое упрощает сбор показаний и\u00A0значительно уменьшает затраты энергосбытовой компаний при\u00A0учете потребления электроэнергии",
    nameBtn: "Узнать подробнее",
    isCallbackBlock: false,
    img: productImg2,
    link: ROUTE_ASKUE_PAGE,
  },
];

export const ListInfo: Array<{
  item: string;
  sublist?: Array<string>;
}> = [
  { item: "Cнижение потерь на 50%" },
  { item: "Экономия до 100 000 рублей на одном приборе учета*" },
  { item: "Независимый контроль учета электроэнергии и выездных бригад" },
  { item: "Сокращение расходов на выездные инспекции" },
];

export const introInfoBlocks: Array<{
  img: string;
  title: string;
  text?: any;
}> = [
  {
    img: introCardImg1,
    title: "Загрузите данные из АСКУЭ",
    text: (
      <CustomTypography variant="p4_regular">
        Наш продукт подходит для компаний, которые используют{" "}
        <Link to={`/${ROUTE_ASKUE_PAGE}`}>
          автоматизированную систему учета электроэнергии (АСКУЭ)
        </Link>
      </CustomTypography>
    ),
  },
  {
    img: introCardImg2,
    title: "Система проведет анализ",
    text: (
      <CustomTypography variant="p4_regular">
        Используем способности нейронной сети для молниеносной обработки данных Вашей энергосети
      </CustomTypography>
    ),
  },
  {
    img: introCardImg3,
    title: "Определите точки потерь",
    text: (
      <CustomTypography variant="p4_regular">
        На основании проведенного анализа, система обнаружит области подозрительной активности с
        точной геолокацией и адресами мест возможных хищений электроэнергии.
      </CustomTypography>
    ),
  },
];

export const accordionInfo: IAccordionCustom[] = [
  {
    title: "Безопасно ли в условиях санкций?",
    text: "Абсолютно безопасно. Код написан силами МТС. Используется программное обеспечение с открытым кодом, одобренное в РФ.",
  },
  {
    title: "За какой период нужно передать данные для адаптации нейросети?",
    text: "Не меньше 1 года. Естественно, чем данных больше, тем результат будет лучше и точнее. Это касается и частоты дискретизации данных, то есть часовые значения лучше суточных, а получасовые лучше часовых значений.",
  },
  {
    title: "Платформа работает только с конкретными счётчиками?",
    text: "Мы работаем с данными, которые получаем из системы АСКУЭ. При согласовании и желании со стороны заказчика мы можем подключаться непосредственно к счетчикам, чтобы загружать более детальную информацию.",
  },
  {
    title: "Инструкция пользователя",
    pdf: {
      icon: <Icon.PDF />,
      text: "Инструкция входа в личный кабинет",
      link: instructionPdf,
      size: "12.9 Мб",
    },
  },
];

export const seo = {
  title: "Поиск коммерческих потерь электроэнергии в электрических сетях",
  description:
    "Сколько вы теряете? Воспользуйтесь нашим калькулятором для рассчета коммерческих потерь электроэнергии",
};
