import styled from "@emotion/styled";
import { device } from "theme";

export const STitle = styled.h2`
  text-align: left;

  & > .MuiTypography-root {
    display: block;
    color: ${(props) => props.theme.mtsColor.text.inverted.lightMode};
    text-transform: uppercase;
    line-height: 1.4;
    text-align: left;

    &:first-of-type {
      margin-bottom: 12px;
    }

    @media ${device.tabletPlus} {
      line-height: 44px;

      &:first-of-type {
        margin-bottom: 0;
      }
    }
  }
`;

export const SText = styled.p`
  text-align: left;

  & > .MuiTypography-root {
    color: ${(props) => props.theme.mtsColor.text.secondary.darkMode};

    & .MuiTypography-root {
      color: ${(props) => props.theme.mtsColor.text.inverted.lightMode};
    }
  }
`;

export const SBtn = styled.div`
  text-align: left;

  & button {
    min-width: 249px;
  }
`;
